import React, { useState } from "react";
import Layout from "../../components/Layout";
import GeneralTab from "../../components/HTabs";
import Service from "../../components/Services";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
// import Hero from '../../components/Hero';
import About from "../../components/About";
import Document from "../../components/Document";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function PrivateLimitedCompany() {
  const [showLessOne, setshowLessOne] = useState(true);
  const [showLessTwo, setshowLessTwo] = useState(true);
  const [showLessThree, setshowLessThree] = useState(true);
  const [showLessFour, setshowLessFour] = useState(true);
  const [showLessFive, setshowLessFive] = useState(true);

  const showLessOneData = `In India, a private limited company is a type of business structure governed
  by the Companies Act, 2013. It is a popular form of company registration chosen by entrepreneurs
  and small to medium-sized businesses.<br>In a private limited company, the liability of the shareholders
  is limited to the amount of capital they have invested in the company. This means that their personal
  assets are generally protected in case of any financial obligations or liabilities incurred by the company.<br>
  To incorporate a private limited company in India, a minimum of two shareholders and two directors are required. The shareholders can be individuals or corporate entities, and one of the directors must be a resident of India. The maximum number of shareholders in a private limited company is limited to 200.<br>
The shares of a private limited company in India are not publicly traded on a stock exchange. They are held by a close-knit group of shareholders, often family members, friends, or business associates. The transfer of shares in a private limited company is subject to certain restrictions as per the company's articles of association.<br>
Private limited companies in India have several advantages. They offer limited liability protection to shareholders, allowing them to separate personal assets from business liabilities. This structure also provides credibility and enhances the ability to attract investors and raise funds.<br>
Private limited companies are required to maintain proper books of accounts, hold annual general meetings, and file annual financial statements with the Registrar of Companies (RoC). They are subject to regulatory compliance requirements set by the Ministry of Corporate Affairs (MCA).<br>
Private limited companies are governed by a board of directors responsible for the company's management and decision-making. The directors are appointed and can be changed by the shareholders through a formal process.<br>
It is important to note that the specific regulations and requirements for private limited companies in India may evolve over time. Therefore, it is advisable to consult with legal and financial professionals or refer to the latest provisions of the Companies Act, 2013, and other relevant laws while incorporating and operating a private limited company in India.<br>
`;
  const showLessTwoData = `<strong>1.	Limited Liability:</strong> One of the primary advantages is limited liability protection. The personal assets of shareholders are separate from the company's liabilities. In case of financial difficulties or legal issues faced by the company, shareholders' personal assets are generally safeguarded.<br>
  <strong>2.	Credibility and Perception:</strong> A private limited company structure provides credibility and a professional image to the business. It can enhance the reputation of the company among customers, suppliers, and potential investors, as it signifies a formal and well-regulated business entity.<br>
  <strong>3.	Ease of Fundraising:</strong> Private limited companies have better access to funding compared to other business forms. They can raise capital by issuing shares to investors, including venture capitalists, angel investors, and private equity firms. This structure facilitates attracting investments for business growth and expansion.<br>
  <strong>4.	Perpetual Existence:</strong> A private limited company has perpetual existence, which means it is not affected by the death or departure of any shareholder or director. The company's operations can continue regardless of changes in ownership or management, ensuring stability and continuity.<br>
  <strong>5.	Separate Legal Entity:</strong> A private limited company is considered a separate legal entity from its owners. It can enter into contracts, own assets, and sue or be sued in its own name. This distinction provides legal protection and allows the company to conduct business transactions independently.<br>
  <strong>6.	Tax Benefits:</strong> Private limited companies in India can benefit from various tax advantages. They are subject to lower tax rates compared to individual taxpayers. Additionally, they can avail themselves of deductions, exemptions, and incentives provided by the tax laws, promoting tax efficiency and optimization.<br>
  <strong>7.	Easy Transferability of Ownership:</strong> Although private limited companies have restrictions on the transfer of shares, transferring ownership is relatively easier compared to other business forms. Shareholders can sell or transfer their shares to other eligible parties, facilitating changes in ownership and facilitating exit strategies for investors.<br>
  <strong>8.	Employee Benefits:</strong> Private limited companies can offer employee stock options (ESOPs) as a form of compensation. ESOPs allow employees to acquire company shares, aligning their interests with the company's growth and fostering a sense of ownership and loyalty among employees.<br>
  .These advantages make the private limited company structure an attractive choice for entrepreneurs and small to medium-sized businesses in India. However, it is important to consider individual circumstances and seek professional advice when deciding on the most suitable business structure.<br>
  `;
  const showLessThreeData = `
  While there are several advantages to operating a private limited company in India, there are also some potential disadvantages to consider. Here are a few:<br>
  <strong>1.	Compliance Burden:</strong> Private limited companies in India are subject to various legal and regulatory compliance requirements. This includes maintaining proper books of accounts, filing annual financial statements with the Registrar of Companies (RoC), conducting annual general meetings, and complying with tax and labor laws. The administrative burden of compliance can be time-consuming and may require professional assistance.<br>
  <strong>2.	Restriction on Public Fundraising:</strong> Unlike public limited companies, private limited companies cannot raise funds from the public through the issuance of shares. They are limited to raising capital from a restricted number of shareholders. This can potentially limit the access to a larger pool of investors and restrict the company's ability to raise significant capital for expansion.<br>
  <strong>3.	Limited Number of Shareholders:</strong> Private limited companies in India are restricted to a maximum of 200 shareholders. This limitation can hinder the company's ability to invite more investors or shareholders, which may be a drawback if the business requires substantial investment or wants to expand its shareholder base.<br>
  <strong>4.	Transferability of Shares:</strong> Private limited companies often impose restrictions on the transfer of shares. Shareholders may need approval from other shareholders or face other limitations when transferring their shares to others. This can limit liquidity and make it challenging for shareholders to exit or sell their stake in the company.<br>
  <strong>5.	Disclosure of Information:</strong> Private limited companies are required to file annual financial statements with the RoC, which are publicly accessible. This means that certain financial and operational information becomes available to the public. If a company prefers to keep its financial data and business strategies confidential, the requirement for disclosure can be seen as a disadvantage.<br>
  <strong>6.	Higher Setup and Compliance Costs:</strong> Compared to other business structures, setting up a private limited company in India may involve higher initial setup costs. There may be legal and professional fees associated with company incorporation, obtaining necessary licenses, and fulfilling compliance requirements. Ongoing compliance costs, such as audit fees and annual filing fees, can also be relatively higher.<br>
  It's important to note that the disadvantages mentioned above should be evaluated in the context of individual business requirements and goals. While private limited companies may have certain drawbacks, they continue to be a popular choice due to their flexibility, limited liability protection, and ability to attract investors. Seeking professional advice from legal and financial experts is advisable to make an informed decision.<br>
  `;
  const showLessFourData = `Private Limited Company registration in India involves several compliance requirements that must be fulfilled. Here are the key registration compliances for a private limited company:<br>
  <strong>1.	Obtain Director Identification Number (DIN):</strong> The proposed directors of the company need to obtain a DIN by submitting an online application to the Ministry of Corporate Affairs (MCA). DIN is a unique identification number assigned to individuals who are appointed as directors of Indian companies.<br>
  <strong>2.	Digital Signature Certificate (DSC):</strong> Directors also need to obtain a digital signature certificate, which is required for filing electronic documents with the MCA. DSC serves as a digital equivalent of a physical signature and ensures the authenticity and security of electronic documents.<br>
  <strong>3.	Name Reservation:</strong> The company must apply for the reservation of a suitable name through the RUN (Reserve Unique Name) facility provided by the MCA. The name should comply with the naming guidelines prescribed by the MCA.<br>
  <strong>4.	Prepare and File Incorporation Documents:</strong> Once the name is approved, the company needs to prepare and file incorporation documents, including the Memorandum of Association (MoA) and Articles of Association (AoA). These documents outline the company's objectives, rules, and regulations.<br>
  <strong>5.	Pay Stamp Duty and Registration Fees:</strong> Stamp duty and registration fees need to be paid based on the authorized capital of the company. The fees vary depending on the state in which the company is registered. Proof of payment must be submitted along with the incorporation documents.<br>
  <strong>6.	Obtain Permanent Account Number (PAN):</strong> After the company is incorporated, it needs to apply for a Permanent Account Number (PAN) from the Income Tax Department. PAN is required for various tax-related purposes and financial transactions.<br>
  <strong>7.	Obtain Tax Deduction and Collection Account Number (TAN):</strong> If the company is required to deduct or collect taxes at source, it needs to apply for a Tax Deduction and Collection Account Number (TAN) from the Income Tax Department.<br>
  <strong>8.	Register for Goods and Services Tax (GST):</strong> If the company's annual turnover is expected to exceed the prescribed threshold, it must register for GST, a unified indirect tax regime in India. GST registration is mandatory for certain types of businesses.<br>
  <strong>9.	Compliance with Labour Laws:</strong> Private limited companies are required to comply with various labor laws, including obtaining employee-specific registrations such as Employees' Provident Fund Organization (EPFO) registration and Employees' State Insurance Corporation (ESIC) registration, if applicable.<br>
  <strong>10.	Statutory Registers and Records:</strong> Private limited companies must maintain various registers and records, such as a register of members, register of directors, minutes of meetings, and other statutory records, as required by the Companies Act, 2013.<br>
  It's important to note that these are general compliance requirements, and specific requirements may vary based on the nature of the business and the applicable laws. Engaging a professional, such as a company secretary or a chartered accountant, can help ensure proper compliance with all legal and regulatory obligations.<br>
  `;
  const showLessFiveData = `Private Limited Companies in India are subject to various ongoing compliance requirements after their incorporation. Here are some key compliances that private limited companies need to adhere to:<br>
  <strong>1.	Annual General Meeting (AGM):</strong> Private limited companies are required to hold an AGM within six months from the end of each financial year. The AGM should cover matters such as the approval of financial statements, declaration of dividends, appointment or reappointment of auditors, and any other significant business matters.<br>
  <strong>2.	Financial Statements:</strong> Private limited companies must prepare and file financial statements, including the balance sheet, profit and loss account, cash flow statement, and notes to accounts, as per the prescribed format and timelines. These statements should provide a true and fair view of the company's financial position.<br>
  <strong>3.	Annual Return:</strong> Companies are required to file an annual return with the Registrar of Companies (RoC) within 60 days from the date of the AGM. The annual return provides details about the company's directors, shareholders, registered office, and share capital structure.<br>
  <strong>4.	Board Meetings:</strong> Private limited companies must conduct regular board meetings at least four times a year. These meetings are essential for discussing strategic decisions, financial matters, approving contracts, and monitoring the company's overall performance. Minutes of these meetings should be maintained as per legal requirements.<br>
  <strong>5.	Statutory Registers:</strong> Private limited companies need to maintain various statutory registers, including registers of members, directors, and charges. These registers should be updated and kept at the registered office or any other location as per the provisions of the Companies Act, 2013.<br>
  <strong>6.	Appointment and Remuneration of Directors:</strong> The appointment and remuneration of directors should be carried out in compliance with the Companies Act, 2013. Any changes in the board of directors, such as appointments, resignations, or removals, should be filed with the RoC within the specified timeframes.<br>
  <strong>7.	Tax Compliance:</strong> Private limited companies must comply with tax laws, including timely payment of income tax, filing of tax returns, and adhering to Goods and Services Tax (GST) provisions, if applicable. They need to maintain proper books of accounts and other financial records for tax purposes.<br>
  <strong>8.	Compliance with Labour Laws:</strong> Private limited companies need to comply with various labor laws, such as the Employees' Provident Fund Act, Employees' State Insurance Act, and other applicable laws related to employees' welfare and benefits.<br>
  <strong>9.	Secretarial Compliance:</strong> Private limited companies must ensure compliance with secretarial requirements, including the appointment of a qualified Company Secretary (if applicable), maintenance of registers and records, and filing of necessary forms and returns with the RoC.<br>
  <strong>10.	Compliance with Other Applicable Laws:</strong> Depending on the nature of the business, private limited companies may need to comply with industry-specific regulations, environmental laws, data protection laws, intellectual property laws, and any other laws and regulations relevant to their operations.<br>
  It's important for private limited companies to stay updated with the latest legal and regulatory developments and ensure timely compliance with all applicable requirements. Seeking guidance from professionals, such as company secretaries or chartered accountants, can assist in fulfilling these compliance obligations efficiently and effectively.<br>
  `;

  var resultshowLessOne = showLessOne
    ? showLessOneData.slice(0, 250)
    : showLessOneData;
  var resultshowLessTwo = showLessTwo
    ? showLessTwoData.slice(0, 250)
    : showLessTwoData;
  var resultshowLessThree = showLessThree
    ? showLessThreeData.slice(0, 250)
    : showLessThreeData;
  var resultshowLessFour = showLessFour
    ? showLessFourData.slice(0, 250)
    : showLessFourData;
  var resultshowLessFive = showLessFive
    ? showLessFiveData.slice(0, 250)
    : showLessFiveData;

  /* Package Data */
  const PackageCompData = {
    header: `Private Limited Company Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `9999`,
    f_price: `14999`,
    includes: true,
    orginal: false,
    contens: false,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data Without Form*/
  const PvtAboutData = {
    title: `Private Limited Company Service`,
    sub_title: `Private Limited Company Registration in just 7 Days`,
    paragraph: `In India, a private limited company is a type of business structure governed by the Companies Act,
    2013. It is a popular form of company registration chosen by entrepreneurs and small to medium-sized businesses.
    In a private limited company, the liability of the shareholders is limited to the amount of capital they have
    invested in the company. This means that their personal assets are generally protected in case of any financial
    obligations or liabilities incurred by the company.`,
    header: `Our Company Registration Package Includes`,
    points: [
      `Free Compliance Software`,
      `Self MOA & AOA`,
      `Live DIN & DSC for 2 Directors`,
      `Live ROC Fees, PAN & TAN`,
    ],
  };
  /* Slider */
  // const PrivateSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Private Limited Company Registration`,
  //     content: `Apply for Private Limited Company Registration with TODAYFILINGS Experts`,
  //     image: '/imgs/business/sliders/pvt-slider.png',
  //     alt_tag:
  //       'Best Online Private Limited Company Registration Service in Hosur',
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 10,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Private Limited Company Registration`,
  //     content: `Best Private Limited Company Registration Platform`,
  //     image: '/imgs/business/sliders/pvt-slide.png',
  //     alt_tag:
  //       'Best Online Private Limited Company Registration near Bangalore',
  //   },

  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 40,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Private Limited Company Registration in Hosur`,
  //     content: `One the top most success rated private limited company registration service in Hosur`,
  //     image: '/imgs/business/sliders/pvt.png',
  //     alt_tag: 'Best Online Private Limited Company Registration in Hosur',
  //   },
  // ];

  /* Service Data */
  const PrivateCompData = {
    header: `Private Limited Company Registration Advantages`,
    content: `Private limited companies in India offer several advantages to entrepreneurs and business owners. Here are some key benefits:`,
    image: '/imgs/gifs/img5.gif',
    alt_img: 'TODAYFILINGS Online Private Limited Company Registration',
    points: [
      `Limited Liability`,
      `Credibility and Perception`,
      `Ease of Fundraising`,
      `Perpetual Existence`,
      `Separate Legal Entity`,
      `Tax Benefits`,
      `Easy Transferability of Ownership`,
      `Employee Benefits`,
    ],
  };

  /* FaQ Data */

  var PvtLtdFAQ = [
    {
      header: 'What is DSC?',
      body: [
        {
          content: `You can sign electronic documents using the DSC, which is simply a
          Digital Signature Certificate issued by certifying authority (TCS
          and n-Code). Since a DSC is required for every document when forming
          a private limited company in India.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'Can a private company operate more than one business?',
      body: [
        {
          content: `Yes, a Private Company may own more than one firm, but this must be
          stated in the MoA and approved by the registration.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can I register my company with a physical address at my home?',
      body: [
        {
          content: `With the proper documentation, you can have a private company's
          registered office at both a commercial and a residential location. A
          company's registered office is where it receives all official
          correspondence, such as from the MCA or other relevant agencies, and
          where the location is also listed on the site.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header:
        'Can a private limited company issue stock or equity to the public?',
      body: [
        {
          content: `A private limited company is not permitted to flog equity or shares
          to the public.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header:
        'What must a private company do each year in terms of compliance?',
      body: [
        {
          content: `The private limited corporation is required to hold one Annual General
          Meeting (AGM) and about four board meetings per fiscal year, or once
          every four months. Additionally, an independent auditor must
          frequently audit the company's financial statements and accounts. It
          must then submit forms AOC-4 and MGT-7 for annual compliance within
          the specified time frame.`,
          icon: false,
        },
      ],
    },
    {
      // 5
      header: 'What is Private Limited Company',
      body: [
        {
          content: `A Private Limited Company is a small company that is handled by a group of people privately.
          The liability of the members of a Private Limited Company is limited to the number of shares respectively
          held by them. Shares of Private Limited Company cannot be publicly traded.`,
          icon: false,
        },
      ],
    },

    {
      // 6
      header: 'Is an audit mandatory for a startup Private Limited Company?',
      body: [
        {
          content: `Be it a Startup or an established Private Limited company, It is mandatory compliance to have the
          company’s account audited.
            Even the appointment of an auditor within 30 days of the incorporation is mandatory compliance for a
            Private limited company.`,
          icon: false,
        },
      ],
    },
    {
      // 7
      header:
        'When should I file the Annual returns of my Private Limited Company?',
      body: [
        {
          content: `Private Limited Companies are required to file their Annual Accounts and Returns disclosing details
          of its shareholders, directors, etc to the Registrar of Companies. Such compliances are required to be made
          once a year.`,
          icon: false,
        },
      ],
    },
    {
      // 8
      header:
        'What is the minimum turnover and capital required for Private Limited Company in India?',
      body: [
        {
          content: `There exists no bar on turnover or capital in a private Limited Company, unlike One person Company.`,
          icon: false,
        },
      ],
    },
    {
      // 9
      header:
        'Is registration necessary for Private Limited Companies in India?',
      body: [
        {
          content: `Yes, Registration for a Private Limited Company is necessary as, without registration, there can be no existence of a company.`,
          icon: false,
        },
      ],
    },
  ];
  /* Tabs Data */
  const PvtLtdTabsData = {
    id: 'features',
    heading: 'Registration Procedure',
    paragraph: `India has a thriving economy that is constantly expanding and
    offers many chances. The economy will grow if a company is
    registered as a private one in India. Additionally, this will
    boost the number of startups and give them an advantage over
    others who have not yet registered as private limited
    businesses. With carefully crafted rules and regulations that
    adhere to the law, the Ministry of Corporate Affairs controls
    the registration process for private companies.`,
    body: [
      {
        tab_title: 'Identification',
        tab_content: [
          {
            content_title: 'Step-1',
            content_paragraph:
              'Applying for Digital Signature Certificate or DSC.',
            points: [],
          },
          {
            content_title: 'Step-2',
            content_paragraph:
              'Applying for the Director Identification Number or DIN',
            points: [
              `DIN is a unique Director Identification number allotted by the Central Government to any person intending to be a Director or an existing director of a company.`,
            ],
          },
        ],
        content_img: '/imgs/business/dsc-pvt.png',
        alt_tag: 'TODAYFILINGS Private Limited Company Registration',
      },
      {
        tab_title: 'Document Drafting',
        tab_content: [
          {
            content_title: 'Step-3',
            content_paragraph: `Determining the availability of a name and applying for
            it.`,
            points: [],
          },
          {
            content_title: 'Step-4',
            content_paragraph: `Creating the AoA, MoA, and other paperwork required to
            register a private limited corporation by the Ministry of
            Corporate Affairs Following that, this should be submitted
            electronically along with the online registration for the
            private limited company.`,
            points: [
              `Apply to the concerned RoC to ascertain the availability of name.`,
              `The AoA and MoA lays down the rules and regulations for the
              internal management of the company.`,
            ],
          },
        ],
        content_img: '/imgs/business/document-pvt.png',
        alt_tag:
          'TODAYFILINGS is the Best Private Limited Company Document Drafting',
      },
      {
        tab_title: 'Pan Certificate',
        tab_content: [
          {
            content_title: 'Step-5',
            content_paragraph: `For the purpose of registering the company, an application
            must be made to get a TAN and PAN.`,
            points: [],
          },
          {
            content_title: 'Step-6',
            content_paragraph: `The RoC (Registrar of Companies) will issue or award a
            Certificate of Incorporation with a PAN and TAN upon the
            submission of an application.`,
            points: [
              `Tax Deduction Account Number or Tax Collection Account
              Number is a 10 -digit alpha-numeric number issued by the
              Income-tax Department.`,
              `PAN is for all taxpayers living in the country, be it an
              individual, a partnership, or a company. Furthermore, it
              also acts as identity proof.`,
              `Registrars of Companies (ROC) appointed under Section
              609 of the Companies Act covering the various States and
              Union Territories are vested with the primary duty of
              registering companies.`,
            ],
          },
        ],
        content_img: '/imgs/business/pan-pvt.png',
        alt_tag:
          'TODAYFILINGS is the Best Private Limited Company Registration in Hosur',
      },
      {
        tab_title: 'Bank Account',
        tab_content: [
          {
            content_title: 'Step-7',
            content_paragraph: `opening a current account in the company's name at a bank
            that has been authorized`,
            points: [],
          },
          {
            content_title: 'Step-8',
            content_paragraph: `Procedure to register a private limited company.`,
            points: [
              `It is best to apply for the required licenses and register under other
              acts, such as GST, Income Tax, Customs, DGFT guidelines
              etc.`,
              `A 'Private Company' is a limited company formed with minimum of 2
              Shareholders and 2 Directors. The maximum number of
              members in a private company is restricted to 200.
              Shareholders could be individuals, companies or LLPs,
              but only individuals can become directors of a company.`,
            ],
          },
        ],
        content_img: '/imgs/business/bank.png',
        alt_tag:
          'TODAYFILINGS is the Best Private Limited Company Registration Near Bangalore',
      },
    ],
  };

  /* Document Section Data */
  const PvtLtdDocData = {
    id: 'pvt-doc',
    heading: `Private Limited Company`,
    sub_heading: `Documents required for private limited company registration
    online in India`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/bregistration/pancard.png`,
        alt_tag: `form 16`,
        header: `KYC Details`,
        description: `Directors and Shareholders of Indian Nationals should submit a
        PAN card copy with the email and phone numbers for private company registration. `,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/bregistration/passport.png`,
        alt_tag: `Passport`,
        header: `Identity Proof`,
        description: `Shareholder and director identity documentation Foreign nationals
        must have a valid passport and other necessary documents.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/bregistration/photo.png`,
        alt_tag: `Bank Statement`,
        header: `Address Proof`,
        description: `Proof of shareholders' and directors' addresses, such as the
        most recent telephone, electricity, or bank account
        statement and passport-size photos are required.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/bregistration/bankstatement.png`,
        alt_tag: `Business Establishment`,
        header: ` Business Establishment`,
        description: `If the property is owned sale deed is required or the property is rented the rental agreement is required for the establishment.`,
      },
      // {
      //   col: `col-lg-4 col-md-6`,
      //   delay: '400',
      //   img: `/imgs/icons/bregistration/photo.png`,
      //   alt_tag: `Passport Size Photo`,
      //   header: `Passport size photo`,
      //   description: `The shareholders' and directors' passport-size photos are
      //   another necessity. Here, it's crucial to make sure that this
      //   is current.`,
      // },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/bregistration/electric.png`,
        alt_tag: `Business Address Proof`,
        header: `Business Address Proofs`,
        description: `Business address documentation, such as the most recent
        telephone or electricity bills for the registered office.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/bregistration/certificate.png`,
        alt_tag: `No Objection Certificate`,
        header: `No Objection Certificate`,
        description: `Updated documents and the NOC or No Objection Certificate
        must be received from the owners of the registered office.`,
      },
    ],
  };
  /* ImgSlider Data */
  const PvtImages = [
    "/imgs/registration/msme/msme.png",
    "/imgs/registration/msme/msme-reg.png",
    "/imgs/registration/msme/ms-me.png",
  ];
  /*  Slider Content Component Data */
  const PvtSCData = {
    id: "pvt-requirements",
    background: "main-content",
    mt_div: "",
    header: "Requirements for Pvt. Ltd Registration in India",
    content: [
      {
        points: `Minimum 2 directors. `,
        icon: true,
      },
      {
        points: `Minimum 2 Share Holders.`,
        icon: true,
      },
      {
        points: `Director and shareholder should be same person. `,
        icon: true,
      },
      {
        points: `One of the directors must be Indian resident.`,
        icon: true,
      },
    ],
    images: PvtImages,
  };
  return (
    <div>
      <Seo
        title="Private Limited Company"
        keywords="Keywords"
        description="Description"
        name="TODAYFILINGS"
        type="Website"
        author="TODAYFILINGS"
      />
      <Layout>
        <main id="main">
          {/* <Hero sliders={PrivateSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={PvtAboutData} />

          <Counter />

          <Service item={PrivateCompData} />

          <Document docSecData={PvtLtdDocData} />

          <GeneralTab HTabsData={PvtLtdTabsData} />
          <SliderContent ScSection_data={PvtSCData} />
          <Cta />

          <FAQAccord items={PvtLtdFAQ} />
          <section className="features">
            <div className="container mt-5">
              {/* result show 1 */}
              <div className="header2">
                <h3>
                  What is a Private Limited Company?
                </h3>
                <div className="bar"></div>
              </div>
              <p
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: resultshowLessOne }}
              ></p>

              <button
                className="btn btn-primary"
                onClick={() => setshowLessOne((prev) => !prev)}
              >
                {showLessOne ? "Show more" : "Show Less"}
              </button>
            </div>
            {/* result show 2 */}
            <div className="container mt-5">
              <div className="header2">
                <h3>Advantages of Private Limited Company in India</h3>
              </div>
              <p
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: resultshowLessTwo }}
              ></p>
              <button
                className="btn btn-primary"
                onClick={() => setshowLessTwo((prev) => !prev)}
              >
                {showLessTwo ? "Show more" : "Show Less"}
              </button>
            </div>
            {/* result show 3 */}
            <div className="container mt-5">
              <div className="header2">
                <h3>Disadvantages of Private Limited Company in india</h3>

                <div className="bar"></div>
              </div>
              <p
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: resultshowLessThree }}
              ></p>
              <button
                className="btn btn-primary"
                onClick={() => setshowLessThree((prev) => !prev)}
              >
                {showLessThree ? "Show more" : "Show Less"}
              </button>
            </div>
            {/* result show 4 */}
            <div className="container mt-5">
              <div className="header2">
                <h3>Private Limited Company Registration Compliances ?</h3>

                <div className="bar"></div>
              </div>
              <p
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: resultshowLessFour }}
              ></p>
              <button
                className="btn btn-primary"
                onClick={() => setshowLessFour((prev) => !prev)}
              >
                {showLessFour ? "Show more" : "Show Less"}
              </button>
            </div>
            {/* result show 5 */}
            <div className="container mt-5">
              <div className="header2">
                <h3>Private Limited Company Compliances ?</h3>

                <div className="bar"></div>
              </div>
              <p
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: resultshowLessFive }}
              ></p>
              <button
                className="btn btn-primary"
                onClick={() => setshowLessFive((prev) => !prev)}
              >
                {showLessFive ? "Show more" : "Show Less"}
              </button>
            </div>
          </section>
        </main>
      </Layout>
    </div>
  );
}
